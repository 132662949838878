import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import {
  Nav, NavItem, NavLink,  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button, ListGroup, ListGroupItem
} from 'reactstrap';


import LogoHeader from '../components/logoheader/logoHeader'

import '../css/menu.css';
import menuData from '../data/menu'

import pizzasizeguide from '../images/pizza-size-guide.png';

class Menu extends React.Component {
  constructor(props)
  {
    super(props);
    const { activeSubMenu } = this.props;
   
    this.state={
      activeSubmenu:activeSubMenu,
      menuData: menuData
    }

    this.activateMenu = this.activateMenu.bind(this);
  }

  activateMenu(subMenu){
		this.setState({
				activeSubmenu:subMenu,
				menuData: menuData});
		return false;
  }

  render(){
  	return (
    <>
    <LogoHeader/>
     <Container>
     	<Row className="sticky-top" id="menuRow">
     		<Col>
	     		<Nav pills justified horizontal="center">
		     		{Object.keys(this.state.menuData).map(key => (
		     			<NavItem key={key}>
					    	<NavLink  href="#" className={(this.state.activeSubmenu == key ? 'active' : '')}
					    		onClick={this.activateMenu.bind(null, key)}
					    	>
					      		{key}
					    	</NavLink>
					  	</NavItem>
		     		))}
					</Nav>
				</Col>
     	</Row>
     	<Row className="sticky-top" className="pricingPolicyRow">
     		<div>
     			Please note that these prices apply exclusively to dine-in customers. Unfortunately, we may not be able to extend these rates to takeout, to-go, or delivery orders made via phone or walk-in. We apologize for any inconvenience this may cause and appreciate your understanding. We strongly encourage placing orders online through our <a style={{ display: 'inline'}} href="https://order.online/store/22922068" alt="Order Online OC Pizza Mission Viejo">website</a> for the best experience and access online exclusive offers.
     		</div>
     	</Row>
     	{(this.state.activeSubmenu == "pizza") &&
     		<>
	     		<Row>
	     			<Col xs="4" md="2">
	     				<Button className="pizzaSizeButton">S<br/>10"<br/>6-Slices</Button>
	     			</Col>
	     			<Col xs="4" md="2">
	     				<Button className="pizzaSizeButton">M<br/>12"<br/>8-Slices</Button>
	     			</Col>
	     			<Col xs="4" md="2">
	     				<Button className="pizzaSizeButton">L<br/>14"<br/>10-Slices</Button>
	     			</Col>
	     			<Col xs="4" md="2">
	     				<Button className="pizzaSizeButton">XL<br/>16"<br/>12-Slices</Button>
	     			</Col>
	     			<Col xs="4" md="2">
	     				<Button className="pizzaSizeButton">Jumbo<br/>18"<br/>16-Slices</Button>
	     			</Col>
	     		</Row>
	     		<Row>
	     			<h3> Build Your Own </h3>
	     					<Col lg="12" xs="12" md="4">
	     						<Card style={{margin: "20px 0", padding: "10px 20px", textAlign: "center"}}>
	     							{this.state.menuData[this.state.activeSubmenu]['buildYourOwn']['size'].map((item)=>(
	     									<CardText>{item.title}</CardText>
	     							))}
	     						</Card>
	     					</Col>
	     			
	     		</Row>
	     		<Row>
	     			<Col xs="12" md="12" style={{padding: "30px 20px"}}>
	     				<b>Toppings: </b>{this.state.menuData[this.state.activeSubmenu]['buildYourOwn']['toppings']}
	     			</Col>
	     		</Row>
	     		<Row>
	     			<h3> Speciality </h3>
		     		<Col xs="12" md="6">
		     			<Card style={{margin: "20px 0"}}>
		     				<CardBody>
		     					$18.99 - SM 6 slices <br/>
		     					$23.99  - MED 8 slices <br/>
		     					$28.99  - LG 10 slices <br/> 
		     					$32.99  - XL 12 slices <br/> 
		     					$34.99  - JUMBO 14 slices<br/><br/>
		     					<h4>O.C. Signature</h4>
		     					<p>Pepperoni, Mushrooms, Red Onions, Green Peppers, Black Olives & Sausage with Extra Cheese</p>
		     					<br/>
		     					<h4>BBQ Chicken</h4>
		     					<p>BBQ Chicken, Red Onions & Cilantro</p>
		     					<br/>
		     					<h4>The Meat Lover </h4>
		     					<p>Pepperoni, Canadian Bacon, Meatballs & Sausage</p>
									<br/>
		     					<h4>Pesto Pizza </h4>
		     					<p>Made with Pesto sauce ( instead of traditional pizza sauce ), topped with Tomatoes, Spinach, Artichoke</p>
		     				</CardBody>
		     			</Card>
		     			<Card style={{margin: "20px 0"}}>
		     				<CardBody>
		     					$16.99 - SM 6 slices <br/>
		     					$21.99  - MED 8 slices <br/>
		     					$26.99  - LG 10 slices <br/> 
		     					$30.99  - XL 12 slices <br/> 
		     					$33.99  - JUMBO 14 slices<br/><br/>
		     					<h4>Hawaiian Delight</h4>
									<p>Canadian Bacon, Pineapple & Bacon</p>
									<br/>
									<h4>Margherita</h4>
									<p>Fresh Tomatoes, Basil & Garlic</p>
									<br/>
									<h4>Vegetarian</h4>
									<p>Mushrooms, Onions, Black Olives, Green Peppers & Tomato</p>
									<br/>
									<h4>3 Cheese White Pizza</h4>
									<p>Olive Oil, Garlic & Cheese Mix (Ricotta, Mozzarella & Parmesan)</p>
		     				</CardBody>
		     			</Card>
		     		</Col>
	     		</Row>
     		</>
     	}
     	{(this.state.activeSubmenu == "wine") &&
     		<Row style={{padding: "10px 20px 50px"}}>
     				  <Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					<h3 className="drinksHeader">House Red Wines</h3>
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Pinot Noir Black Ridge  -     $7.00(GL) $21(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Cabernet Black Ridge      -	 $8.00(GL) $24(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					<h3 className="drinksHeader">Speciality Red Wines</h3>
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Pinot Noir San Simeon     - $13(GL) $39(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Pinot Noir 41 Highland	  - $8(GL) $24(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Pinot Noir Black Ridge	  - $8(GL) $24(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Cabernet Maddalena	    - $12(GL) $36(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Cabernet 41 Highland	  - $8(GL) $24(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Merlot Crane Lake          - $8(GL) $24(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Red Blend 41 Highland    - $10(GL) $30(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					<h3 className="drinksHeader">House White Wines</h3>
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Chardonnay Black Ridge -   $8.00(GL) $24(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Sauvignon blanc Tierra   -   $8.00(GL) $24(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
							<h3 className="drinksHeader">Speciality White Wines</h3>
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Chardonnay Maddalena       -   $9(GL) $27(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Sauvignon blanc San Simeon -   $11(GL) $34(BTL)
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     					Pinot Grigio - $9(GL) $27(BTL)
	     				</Col>
     		</Row>
      }
      {(this.state.activeSubmenu == "beers") &&
      	<Row style={{padding: "20px 20px 50px"}}>
      			<Col xs="12" md="4" >
      				<Card style={{margin: "10px 0"}}>
								<CardBody className="menuItemCardBody">
								  <CardTitle style={{ fontWeight: 'bold' }}>$6 (Pint), $10(Large), $18.99(Pitcher)</CardTitle>
								  <CardText key={1}>Bud Lt<br/>Coors Lt<br/>Michelob Ultra</CardText>
								</CardBody>
							</Card>
      			</Col>
      			<Col xs="12" md="4" >
      				<Card style={{margin: "10px 0"}}>
								<CardBody className="menuItemCardBody">
								  <CardTitle style={{ fontWeight: 'bold' }}>$8.50 (Pint), $17(Large), $28.99 (Pitcher)</CardTitle>
								  <CardText key={1}>
								  	Blood Orange - Cali Squeeze<br/>
										Double Nickel - Docent<br/>
										Harland Hazy IPA<br/>
										Racks & Balls IPA<br/>
									</CardText>
								</CardBody>
							</Card>
      			</Col>
      			<Col xs="12" md="4" >
      				<Card style={{margin: "10px 0"}}>
								<CardBody className="menuItemCardBody">
								  <CardTitle style={{ fontWeight: 'bold' }}>$7.50 (Pint), $14.99(Large), $26.50(Pitcher)</CardTitle>
								  <CardText key={1}>
								  	Modelo<br/>
			     					805<br/>
			     					Cougar Juice<br/>
										HuckleBerry - BreweryX<br/>
										Truly<br/>
										Twisted Tea<br/>
								  </CardText>
								</CardBody>
							</Card>
      			</Col>
	     	</Row>
      }
      {(this.state.activeSubmenu == "happy hour") &&
      	<Row style={{padding: "20px 20px 50px"}}>
      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
							Monday to Thursday ( 3 PM to 8 PM ) <br/> 
							Friday ( 3 PM to 6 PM )
     				</Col>
      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     				<h3 className="drinksHeader">Pizza Slice & Beer</h3>
	     			</Col>
	     			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
							1 Slice & Bud Lt/Coors Lt Pint - $6 <br/>
							1 Slice & Premium Beer Pint - $8 <br/>
     				</Col>
      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     				<h3 className="drinksHeader">Bud Lt / Coors Lt</h3>
	     			</Col>
      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
							Pint $4<br/>
							Schooner $7<br/>
							Pitcher $15<br/>
     				</Col>
     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     				<h3 className="drinksHeader">Premium Beers</h3>
	     			</Col>
      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
							Pint $6<br/>
 							Schooner $10<br/>
							Pitcher $19<br/>
     				</Col>
     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     				<h3 className="drinksHeader">Glass of Wine for $6</h3>
	     			</Col>
	     			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
							Cabernet Black Ridge<br/>
							Pinot Noir Black Ridge<br/>
							Merlot Crane Lakes<br/>
							Chardonnay Black Ridge<br/>
							Sauvignon Blanc Tierra<br/>
							Pinot Grigios
     				</Col>
      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     				<h3 className="drinksHeader">Appetizers for $6.99</h3>
	     			</Col>
      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
     					Garlic Knots<br/>
							Meatball Sliders<br/>
							Fried Zucchini<br/>
							Jalapeno Poppers<br/>
							Onion Rings<br/>
							Tots<br/>
							Mozzarella Sticks<br/>
     				</Col>
     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
	     				<h3 className="drinksHeader">Pizza for $8.99</h3>
	     			</Col>
      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
     					10" One Topping Pizza 
     				</Col>
      	</Row>
      }
     	{(this.state.activeSubmenu != "pizza" && this.state.activeSubmenu != "drinks") &&
     		<Row>
	     		{this.state.menuData[this.state.activeSubmenu].map((item,idx)=>(
	     			<Col xs="12" md="4" key={idx}>
	     				<Card style={{margin: "10px 0"}}>
								<CardBody className="menuItemCardBody">
								  <CardTitle style={{ fontWeight: 'bold' }}>{item.title} - ${item.price}</CardTitle>
								  <CardText key={1}></CardText>
								  <CardText key={2}>{item.desc}</CardText>
								</CardBody>
							</Card>
	     			</Col>
	     		))}
	     	</Row>
     	}
     </Container>
    </>)
  }
}

export default Menu;